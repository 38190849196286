import { graphql } from 'gatsby';
import React, { useState } from 'react';
import { IArticleNode } from '../types/articlesTypes';
import { ICareerNode } from '../types/careersTypes';
import { IServiceNode } from '../types/servicesTypes';
import { IEducationNode } from '../types/educationTypes';
import { IHomePageResponse } from '../types/homeTypes';
import { getHomeEntititesHandler } from '../utils/homeHandlers';
import HomeContactUsSection from '../components/home-contact-us-section/HomeContactUsSection';
import HomeAboutSection from '../components/home-about-section/HomeAboutSection';
import HomeArticlesSection from '../components/home-articles-section/HomeArticlesSection';
import HomeCareersSection from '../components/home-careers-section/HomeCareersSection';
import HomeIndustrySection from '../components/home-industry-section/HomeIndustrySection';
import HomeMainSection from '../components/home-main-section/HomeMainSection';
import HomeServicesSection from '../components/home-services-section/HomeServicesSection';
import Layout from '../components/layout/Layout';
import Spinner from '../components/spinner/Spinner';

type HomeTemplateProps = {
   articles: IArticleNode[]
   careers: ICareerNode[]
   services: IServiceNode[]
   educationKeys: IEducationNode[]
   homePageContent: any,
   setIsFetching: (value: boolean) => void
}

export const HomeTemplate: React.FC<HomeTemplateProps> = ({ 
   articles, 
   careers, 
   services, 
   educationKeys, 
   homePageContent, 
   setIsFetching
}) => {
   
   return (
      <main>
         <HomeMainSection 
            title={homePageContent.title}
            subtitle={homePageContent.subtitle}
            background={homePageContent.background}
            rhombusImage={homePageContent.rhombusImage}
         />
         <HomeAboutSection content={{
            aboutBackground: homePageContent.aboutBackground,
            aboutRhombus: homePageContent.aboutRhombus,
            aboutHeading: homePageContent.aboutHeading,
            aboutSubheading: homePageContent.aboutSubheading
         }} />
         <HomeServicesSection
            servicesBackground={homePageContent.servicesBackground}
            servicesHeading={homePageContent.servicesHeading}
            servicesSubheading={homePageContent.servicesSubheading}
            services={services}
         />
         <HomeIndustrySection
            industryBackground={homePageContent.industryBackground}
            industryHeading={homePageContent.industryHeading}
            industrySubheading={homePageContent.industrySubheading}
            educationKeys={educationKeys}
         />
         <HomeCareersSection 
            careers={careers} 
            careersBackground={homePageContent.careersBackground} 
            careersHeading={homePageContent.careersHeading} 
         />
         <HomeArticlesSection resourcesBackground={homePageContent.resourcesBackground} articles={articles} />
         <HomeContactUsSection
            content={{
               formBackground: homePageContent.formBackground,
               formHeading: homePageContent.formHeading,
               formRhombus: homePageContent.formRhombus
            }}
            setIsFetching={setIsFetching}
         />
      </main>
   )
}

type HomeContainerProps = {
   data: IHomePageResponse
}

const HomeContainer: React.FC<HomeContainerProps> = ({ data }) => {
   const { edges } = data.allMarkdownRemark;
   const { articles, services, careers, educationKeys, homePageContent } = getHomeEntititesHandler(edges);
   const [isFetching, setIsFetching] = useState<boolean>(false);

   return (
      <Layout>
         {isFetching && <Spinner />}
         <HomeTemplate
            articles={articles}
            careers={careers}
            services={services}
            educationKeys={educationKeys}
            homePageContent={homePageContent}
            setIsFetching={setIsFetching}
         />
      </Layout>
   );
};

export const homePageQuery = graphql`
  query {
    allMarkdownRemark(
        filter: {frontmatter: {templateKey: {in: [
            "home-template", 
            "service-page-template",
            "career-template",
            "article-template",
            "education-template"
        ]}}}
      ) {
        edges {
          node {
            id
            html
            frontmatter {
                templateKey
                path
                
                orderNumber
                date(formatString: "MMMM DD, YYYY")
                title
                subtitle
                icon

                featuredImage
                bgIcon
                careerName
                location
                description

                background
                rhombusImage
                aboutHeading
                aboutSubheading
                aboutRhombus
                aboutBackground
                servicesHeading
                servicesSubheading
                servicesBackground
                industryHeading
                industrySubheading
                careersHeading
                careersBackground
                resourcesHeading
                resourcesBackground
                formHeading
                formRhombus
                formBackground
            }
          }
        }
      }
    }
`;

export default HomeContainer;



