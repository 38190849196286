import classNames from 'classnames';
import React from 'react';
import { IArticleNode } from '../../types/articlesTypes';
import ArticleCard from '../article-card/ArticleCard';
import TitleH2 from '../title-h2/TitleH2';
import * as styles from '../../pages/index.module.scss';
import { Zoom } from 'react-reveal';

type HomeArticlesProps = {
    articles: IArticleNode[],
    resourcesBackground: string
}

const HomeArticlesSection: React.FC<HomeArticlesProps> = ({ articles, resourcesBackground }) => {
    return (
        <section className={styles.articles} style={{ backgroundImage: `url(${resourcesBackground})` }}>
            <div className={classNames(classNames, styles.articlesContainer, 'wrapper')}>
                <TitleH2>Resources and Insights</TitleH2>

                <Zoom>
                    <div className={styles.articlesItems}>
                        {articles.map(article => <ArticleCard key={article.node.id} article={article.node} />)}
                    </div>
                </Zoom>
            </div>
        </section>
    )
}

export default HomeArticlesSection