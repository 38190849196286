import { Link } from 'gatsby';
import React from 'react';
import { EducationFrontmatterType } from '../../types/educationTypes';
import * as styles from './education-card.module.scss';

type EducationCardProps = {
    item: EducationFrontmatterType
}

const EducationCard: React.FC<EducationCardProps> = ({ item }) => {
    const { title, featuredImage, path } = item;

    return (
        <Link to={path} className={styles.industrySectionCard}>
            <span className={styles.industrySectionCardText}>{title}</span>
            <img src={featuredImage} alt="Industry image" />
        </Link>
    )
}

export default EducationCard;
