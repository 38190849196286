import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import * as styles from './career-card.module.scss';

type CareerCardProps = {
   career: {
      location: string
      careerName: string
      path: string
      description: string
   }
}

const CareerCard: React.FC<CareerCardProps> = ({ career }) => {

   const { location, careerName, description, path } = career;

   return (
      <Link to={path} className={styles.container}>
         <StaticImage 
            className={styles.logoImage} 
            src={'../../images/expertise-logo.png'} 
            alt="Logo current company"
            placeholder="tracedSVG"  
         />
         <h3 className={styles.title}>{careerName}</h3>
         <span className={styles.location}>
            <StaticImage 
               className={styles.locationImg} 
               src={'../../images/place.svg'} 
               alt="" 
               aria-hidden="true" 
               placeholder="tracedSVG" 
            />
            {location}
         </span>
         <p className={styles.description}>{description}</p>
      </Link>
   )
}

export default CareerCard