import classNames from 'classnames';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import LinkButton from '../link-button/LinkButton';
import TitleH2 from '../title-h2/TitleH2';
import * as styles from '../../pages/index.module.scss';
import { pagePathEnum } from '../../enums/pathEnum';
import { IServiceNode } from '../../types/servicesTypes';
import ServiceCard from '../service-card/ServiceCard';
import { Slide } from 'react-reveal';

type HomeServicesSectionProps = {
   servicesBackground: string
   servicesHeading: string
   servicesSubheading: string
   services: IServiceNode[]
}

const HomeServicesSection: React.FC<HomeServicesSectionProps> = ({
   servicesBackground,
   servicesHeading,
   servicesSubheading,
   services
}) => {
   const topServices = services.slice(0, 2);
   const bottomServices = services.slice(2, 4);
   return (
      <section className={styles.services} style={{ backgroundImage: `url(${servicesBackground})` }}>
         <div className={classNames(classNames, styles.servicesContainer, 'wrapper')}>
            <TitleH2>{servicesHeading}</TitleH2>

            <p className={styles.servicesSubtitle}>{servicesSubheading}</p>

            <div className={styles.servicesContent}>
               <Slide left>
                  <div className={classNames(classNames, styles.servicesItems, styles.servicesTop)}>
                     {topServices.map(service => <ServiceCard key={service.node.id} service={service.node} />)}
                  </div>
               </Slide>

               <StaticImage className={styles.servicesImage} src={'../../images/section-backgroung/home-page-services-image.png'} alt="Service card image" />

               <Slide right>
                  <div className={classNames(classNames, styles.servicesItems, styles.servicesBottom)}>
                     {bottomServices.map(service => <ServiceCard key={service.node.id} service={service.node} />)}
                  </div>
               </Slide>
            </div>

            <LinkButton className={styles.servicesLink} path={pagePathEnum.SERVICES}>view all services</LinkButton>
         </div>
      </section>
   )
}

export default HomeServicesSection