import classNames from 'classnames';
import React from 'react';
import Button from '../button/Button';
import * as styles from '../../pages/index.module.scss';
import { Fade } from 'react-reveal';

type HomeMainSectionProps = {
    background: string
    title: string
    subtitle: string
    rhombusImage: string
}

const HomeMainSection: React.FC<HomeMainSectionProps> = ({
    background,
    title,
    subtitle,
    rhombusImage
}) => {
    const scrollIntoViewHandle = () => {
        const element = document.getElementById("contactUsSection");
        element && element.scrollIntoView({ behavior: 'smooth' });
    }
    return (
        <section className={styles.main} style={{ backgroundImage: `url(${background})` }}>
            <div className={classNames(classNames, styles.mainContainer, 'wrapper')}>
                <Fade delay={500}>
                    <h1 className={styles.mainTitle}>{title}</h1>
                </Fade>

                <Fade delay={500}>
                    <p className={styles.mainSubtitle}>{subtitle}</p>
                </Fade>

                <div className={styles.mainButtonContainer}>
                    <Fade delay={500}>
                        <Button
                            className={'started-btn'}
                            name={'GET STARTED'}
                            onClick={scrollIntoViewHandle}
                        />
                    </Fade>
                </div>
                <img className={styles.mainImage} src={rhombusImage} alt="Main section" />
            </div>
        </section>
    )
}

export default HomeMainSection
