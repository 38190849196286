import classNames from 'classnames';
import React from 'react';
import { IEducationNode } from '../../types/educationTypes';
import EducationCard from '../education-card/EductionCard';
import TitleH2 from '../title-h2/TitleH2';
import * as styles from '../../pages/index.module.scss';
import { Slide } from 'react-reveal';

type HomeIndustrySectionProps = {
    industryBackground: string
    industryHeading: string
    industrySubheading: string
    educationKeys: IEducationNode[]
}

const HomeIndustrySection: React.FC<HomeIndustrySectionProps> = ({
    industryBackground,
    industryHeading,
    industrySubheading,
    educationKeys
}) => {
    const topEducation = educationKeys.slice(0, 2);
    const bottomEducation = educationKeys.slice(2, 4);
    return (
        <section className={styles.industry} style={{ backgroundImage: `url(${industryBackground})` }}>
            <div className={classNames(classNames, styles.industryContainer, 'wrapper')}>
                <TitleH2 className={styles.industryTitle}>{industryHeading}</TitleH2>

                <p className={styles.industrySubtitle}>{industrySubheading}</p>

                <div className={styles.industryItems}>
                    <Slide top>
                        {topEducation.map(edu => <EducationCard key={edu.node.id} item={edu.node.frontmatter} />)}
                    </Slide>

                    <Slide bottom>
                        {bottomEducation.map(edu => <EducationCard key={edu.node.id} item={edu.node.frontmatter} />)}
                    </Slide>
                </div>
            </div>
        </section>
    )
}

export default HomeIndustrySection