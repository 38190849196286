import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import { pagePathEnum } from '../../enums/pathEnum';
import CareerCard from '../career-card/CareerCard';
import TitleH2 from '../title-h2/TitleH2';
import * as styles from '../../pages/index.module.scss';
import { ICareerNode } from '../../types/careersTypes';
import { Zoom } from 'react-reveal';

type HomeCareersSectionProps = {
    careersBackground: string
    careersHeading: string,
    careers: ICareerNode[]
}

const HomeCareersSection: React.FC<HomeCareersSectionProps> = ({ careers, careersBackground, careersHeading }) => {
    return (
        <section className={styles.careers} style={{ backgroundImage: `url(${careersBackground})` }}>
            <div className={classNames(classNames, styles.careersContainer, 'wrapper')}>
                <TitleH2>{careersHeading}</TitleH2>

                <Zoom>
                    <div className={styles.careersContent}>
                        {careers.map(career => <CareerCard key={career.node.id} career={career.node.frontmatter} />)}
                    </div>
                </Zoom>

                <Link className={styles.careersLink} to={pagePathEnum.CAREERS}>View all careers</Link>
            </div>
        </section>
    )
}

export default HomeCareersSection