import classNames from 'classnames';
import React from 'react';
import * as styles from '../../pages/index.module.scss';
import ContactUsForm from '../contact-us-form/ContactUsForm';
import TitleH2 from '../title-h2/TitleH2';
import { Slide } from 'react-reveal';

type HomeContactUsProps = {
    content: {
        formBackground: string,
        formHeading: string
        formRhombus: string
    },
    setIsFetching: (value: boolean) => void
}

const HomeContactUsSection: React.FC<HomeContactUsProps> = ({ content, setIsFetching }) => {
    const { formBackground, formHeading, formRhombus } = content;
    return (
        <section className={styles.contact} style={{ backgroundImage: `url(${formBackground})` }} id="contactUsSection">
            <div className={classNames(classNames, styles.contactContainer, 'wrapper')}>
                <Slide left>
                    <TitleH2 className={styles.contactTitle}>{formHeading}</TitleH2>
                </Slide>

                <Slide left>
                    <ContactUsForm 
                        className={styles.contactForm} 
                        messageRows={4}
                        setIsFetching={setIsFetching}
                    />
                </Slide>

                <Slide right>
                    <img className={styles.contactRhombus} src={formRhombus} alt="Contact section image" />
                </Slide>
            </div>
        </section>
    )
}

export default HomeContactUsSection