import { IArticleNode } from "../types/articlesTypes";
import { ICareerNode } from "../types/careersTypes";
import { IEducationNode } from "../types/educationTypes";
import { HomePageEdge } from "../types/homeTypes";
import { IServiceNode } from "../types/servicesTypes";

export const getHomeEntititesHandler = (edges: HomePageEdge[]) => {
    const articles: IArticleNode[] = edges
        .filter((edge: HomePageEdge) => edge.node.frontmatter.templateKey === "article-template")
        .map((edge: HomePageEdge) => ({
            node: {
                id: edge.node.id,
                frontmatter: {
                    title: edge.node.frontmatter.title,
                    subtitle: edge.node.frontmatter.subtitle,
                    featuredImage: edge.node.frontmatter.featuredImage,
                    date: edge.node.frontmatter.date,
                    path: edge.node.frontmatter.path,
                    templateKey: edge.node.frontmatter.templateKey
                }
            }
        }));

    const careers: ICareerNode[] = edges
        .filter((edge: HomePageEdge) => edge.node.frontmatter.templateKey === "career-template")
        .map((edge: HomePageEdge) => ({
            node: {
                id: edge.node.id,
                html: edge.node.html,
                frontmatter: {
                    location: edge.node.frontmatter.location,
                    careerName: edge.node.frontmatter.careerName,
                    description: edge.node.frontmatter.description,
                    path: edge.node.frontmatter.path,
                    templateKey: edge.node.frontmatter.templateKey
                }
            }
        }));

    const services: IServiceNode[] = edges
        .filter((edge: HomePageEdge) => edge.node.frontmatter.templateKey === "service-page-template")
        .map((edge: HomePageEdge) => ({
            node: {
                id: edge.node.id,
                html: edge.node.html,
                frontmatter: {
                    icon: edge.node.frontmatter.icon,
                    bgIcon: edge.node.frontmatter.bgIcon,
                    title: edge.node.frontmatter.title,
                    subtitle: edge.node.frontmatter.subtitle,
                    date: edge.node.frontmatter.date,
                    path: edge.node.frontmatter.path,
                    templateKey: edge.node.frontmatter.templateKey
                }
            }
        }))
        .sort((a: IServiceNode, b: IServiceNode) => a.node.frontmatter.title.localeCompare(b.node.frontmatter.title));
    
    const educationKeys: IEducationNode[] = edges
        .filter((edge: HomePageEdge) => edge.node.frontmatter.templateKey === "education-template")
        .map((edge: HomePageEdge) => ({
            node: {
                id: edge.node.id,
                html: edge.node.html,
                frontmatter: {
                    title: edge.node.frontmatter.title,
                    subtitle: edge.node.frontmatter.subtitle,
                    featuredImage: edge.node.frontmatter.featuredImage,
                    date: edge.node.frontmatter.date,
                    path: edge.node.frontmatter.path,
                    templateKey: edge.node.frontmatter.templateKey,
                    orderNumber: edge.node.frontmatter.orderNumber
                }
            }
        }))
        .sort((a: IEducationNode, b: IEducationNode) => a.node.frontmatter.orderNumber - b.node.frontmatter.orderNumber);

    const homePageTemplate: any = edges.filter((edge: HomePageEdge) => edge.node.frontmatter.templateKey === "home-template")[0];
    const homePageContent = homePageTemplate.node.frontmatter;

    return {
        articles,
        services,
        careers,
        educationKeys,
        homePageContent
    }
}