// extracted by mini-css-extract-plugin
export var badge = "article-card-module--badge--VxGuJ";
export var container = "article-card-module--container--FGUov";
export var content = "article-card-module--content--E0BeB";
export var date = "article-card-module--date--fsR96";
export var image = "article-card-module--image--FEjsG";
export var moreBtn = "article-card-module--more-btn--BZuVR";
export var moreImg = "article-card-module--more-img--lss27";
export var subtitle = "article-card-module--subtitle--EQ+xN";
export var timeImg = "article-card-module--time-img--39seI";
export var title = "article-card-module--title--cFXD2";