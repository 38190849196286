// extracted by mini-css-extract-plugin
export var about = "index-module--about--6cOvp";
export var aboutContainer = "index-module--about-container--G74bO";
export var aboutImage = "index-module--about-image--YLwmL";
export var aboutLink = "index-module--about-link--Lx9uB";
export var aboutSubtitle = "index-module--about-subtitle--PqddB";
export var aboutTitle = "index-module--about-title--kHOEk";
export var articles = "index-module--articles--AB3oK";
export var articlesContainer = "index-module--articles-container--Wpq9u";
export var articlesItems = "index-module--articles-items--v6xLF";
export var careers = "index-module--careers--tn6Bg";
export var careersContainer = "index-module--careers-container--9QQ-G";
export var careersContent = "index-module--careers-content--3wkc8";
export var careersLink = "index-module--careers-link--oayKp";
export var contact = "index-module--contact--cYS9y";
export var contactContainer = "index-module--contact-container--D+5Vw";
export var contactForm = "index-module--contact-form--Vd9b0";
export var contactRhombus = "index-module--contact-rhombus--ngr23";
export var contactTitle = "index-module--contact-title--U-MvG";
export var industry = "index-module--industry--PIZvT";
export var industryContainer = "index-module--industry-container--1EF9s";
export var industryItems = "index-module--industry-items--h--f+";
export var industrySubtitle = "index-module--industry-subtitle--ER19H";
export var industryTitle = "index-module--industry-title--PW8rK";
export var main = "index-module--main--NkabX";
export var mainButtonContainer = "index-module--main-button-container--xidh-";
export var mainContainer = "index-module--main-container--s7umo";
export var mainImage = "index-module--main-image--7TWBu";
export var mainSubtitle = "index-module--main-subtitle--eeNsr";
export var mainTitle = "index-module--main-title--pG+J3";
export var services = "index-module--services--e7RMB";
export var servicesBottom = "index-module--services-bottom--8OkNj";
export var servicesContainer = "index-module--services-container--EP3Zb";
export var servicesContent = "index-module--services-content--c-EzE";
export var servicesImage = "index-module--services-image--rRvWc";
export var servicesItems = "index-module--services-items--11Odz";
export var servicesLink = "index-module--services-link--VyLjE";
export var servicesSubtitle = "index-module--services-subtitle--0g8-7";
export var servicesTop = "index-module--services-top--4gf0a";