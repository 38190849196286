import { Link } from 'gatsby';
import React from 'react';
import * as styles from './service-card.module.scss';

type ServiceCardProps = {
   service: {
      id: string
      frontmatter: {
         path: string
         bgIcon: string
         icon: string
         title: string
         subtitle: string
         date: string
         templateKey: string
      }
   }
}

const ServiceCard: React.FC<ServiceCardProps> = ({ service }) => {
   const { path, bgIcon, title, subtitle } = service.frontmatter;

   return (
      <Link to={path} className={styles.serviceCard}>
         <img src={bgIcon} alt="" className={styles.serviceCardIcon} />
         <div className={styles.serviceCardText}>
            <h3 className={styles.serviceCardTextTitle}>{title}</h3>
            <p className={styles.serviceCardTextSubtitle}>{subtitle}</p>
         </div>
      </Link>
   );
};

export default ServiceCard;