import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import TitleH2 from '../title-h2/TitleH2';
import * as styles from '../../pages/index.module.scss';
import { pagePathEnum } from '../../enums/pathEnum';
import { Slide, Zoom } from 'react-reveal';

type HomeAboutSectionProps = {
    content: {
        aboutBackground: string
        aboutRhombus: string
        aboutHeading: string
        aboutSubheading: string
    }
}

const HomeAboutSection: React.FC<HomeAboutSectionProps> = ({ content }) => {
    const { aboutBackground, aboutHeading, aboutRhombus, aboutSubheading } = content;
    return (
        <section className={styles.about} style={{ backgroundImage: `url(${aboutBackground})` }}>
            <div className={classNames(classNames, styles.aboutContainer, 'wrapper')}>
                <Zoom big>
                    <img className={styles.aboutImage} src={aboutRhombus} alt="About section image" />
                </Zoom>

                <Slide left>
                    <TitleH2 className={styles.aboutTitle}>{aboutHeading}</TitleH2>
                </Slide>

                <Slide left>
                    <p className={styles.aboutSubtitle}>{aboutSubheading}</p>
                </Slide>

                <Slide left>
                    <Link className={styles.aboutLink} to={pagePathEnum.ABOUT_US}>
                        LEARN MORE ABOUT US
                    </Link>
                </Slide>
            </div>
        </section>
    )
}

export default HomeAboutSection