import React from 'react';
import { Link } from 'gatsby';
import * as styles from './article-card.module.scss';
import { StaticImage } from 'gatsby-plugin-image';
interface IArticleCardData {
   frontmatter: {
      title: string,
      subtitle?: string,
      featuredImage: string,
      date: string
      path: string
   }
}

type ArticleCardProps = {
   article: IArticleCardData
}

const ArticleCard: React.FC<ArticleCardProps> = ({ article }) => {
   const frontmatter = article.frontmatter;
   return (
      <article className={styles.container}>
         <img className={styles.image} src={frontmatter.featuredImage} alt="" />
         <div className={styles.content}>
            <span className={styles.date}>
               <StaticImage
                  className={styles.timeImg}
                  src={'../../images/time.svg'}
                  alt=""
                  aria-hidden="true"
                  placeholder="tracedSVG"
               />
               {frontmatter.date}
            </span>
            <p className={styles.badge}>
               <span>Security</span>
               <span>9 minute read</span>
            </p>
            <Link to={frontmatter.path}>
               <h3 className={styles.title}>{frontmatter.title}</h3>
            </Link>
            <p className={styles.subtitle}>{frontmatter.subtitle}</p>
            <Link to={frontmatter.path} className={styles.moreBtn}>
               Learn more
               <StaticImage
                  className={styles.moreImg}
                  src={'../../images/arrow.png'}
                  alt=""
                  aria-hidden="true"
                  placeholder="tracedSVG"
               />
            </Link>
         </div>
      </article>
   )
}

export default ArticleCard
